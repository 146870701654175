<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="listUser()">
                    Liste des utilisateurs
                </vk-breadcrumb-item>
                <vk-breadcrumb-item :disabled="true">Utilisateur {{ $route.params.userId }}</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <h5>Utilisateur</h5>
            <div v-if="isLoading">
                <div class="uk-margin-right uk-inline" uk-spinner="ratio:0.75"></div>
                Récupération des informations de l'utilisateur en cours...
            </div>
            <User
                v-if="user"
                :user="user"
                :show-roles-selection="false"
                :user-record-method="recordUser"
                :edit="true"
            />
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import User from '@/components/User'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store'
export default {
    name: 'userEdit',
    components: { User },
    data: () => ({
        user: null,
        isLoading: false,
    }),
    computed: {
        ...mapGetters('users', ['getUserFromList']),
    },
    methods: {
        ...mapMutations('users', ['UPDATE_USERS']),
        ...mapActions('notifications', ['addNotification']),
        listUser() {
            if (store.getters['login/isAdminFunc']) {
                this.$router.push({ name: 'users_suez' })
            } else {
                this.$router.push({ name: 'users' })
            }
        },
        recordUser(userData) {
            return backendApi
                .updateUser(this.$store.state.login.user.token, userData)
                .then((data) => {
                    this.UPDATE_USERS(data)
                    this.addNotification({ status: 'success', message: 'Utilisateur modifié avec succès' })
                    this.$http
                        .getUserById(this.$route.params.userId)
                        .then((res) => {
                            this.user = res
                        })
                        .catch((e) => {
                            store.dispatch('notifications/showWarning', e.response.data.exception.message)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                })
                .catch(backendApi.notificationOnError)
        },
    },
    mounted() {
        this.isLoading = true
        this.$http
            .getUserById(this.$route.params.userId)
            .then((res) => {
                this.user = res
            })
            .catch((e) => {
                store.dispatch('notifications/showWarning', e.response.data.exception.message)
            })
            .finally(() => {
                this.isLoading = false
            })
    },
}
</script>

<style scoped></style>
